import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  font-size: 24px;
  font-weight: 40;
  line-height: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "BigNoodleTitling";
  src: url("/fonts/BigNoodleTitling.ttf.eot") format("woff2");
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 5px;
  width: 0px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 10px;
  width: 0px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 20px;
  width: 0px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 30px;
  width: 0px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 10)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "column")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  width: 100%;
  height: 0%;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 80px;
  font-weight: 200;
  letter-spacing: 0px;
  line-height: 1;
  font-family: "BigNoodleTitling";
  src: url("/fonts/BigNoodleTitling.ttf.eot") format("woff2");
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 30px;
  line-height: 1;
  font-family: "BigNoodleTitling";
  src: url("/fonts/BigNoodleTitling.ttf.eot") format("woff2");
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 30px;
  line-height: 1;
  font-family: "BigNoodleTitling";
  src: url("/fonts/BigNoodleTitling.ttf.eot") format("woff2");
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0;
  }
`;
